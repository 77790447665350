.header-top {
    background: linear-gradient(
        to bottom,
        rgba(181, 0, 0, 1) 0%,
        rgba(66, 0, 0, 1) 96%,
        rgba(13, 0, 0, 1) 99%,
        rgba(13, 0, 0, 1) 100%
    );
}

.header-bottom {
    background: linear-gradient(
        to bottom,
        rgba(64, 0, 1, 1) 0%,
        rgba(64, 0, 1, 1) 1%,
        rgba(158, 0, 3, 1) 4%,
        rgba(96, 2, 3, 1) 34%,
        rgba(33, 0, 0, 1) 84%,
        rgba(33, 0, 0, 1) 93%,
        rgba(143, 0, 0, 1) 96%,
        rgba(20, 0, 0, 1) 98%,
        rgba(20, 0, 0, 1) 100%
    );
}