@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

* {
    font-family: "Kanit", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    background: radial-gradient(
        ellipse at center,
        rgba(125, 0, 0, 1) 0%,
        rgba(125, 0, 0, 1) 3%,
        rgba(33, 0, 0, 1) 91%,
        rgba(33, 0, 0, 1) 100%
    );
}

/* .line-right {
    display: inline-block;
    width: 1px;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    border-right: 1px dotted var(--font);
    vertical-align: sub;
} */

a:link {
    color: #cfccc8;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

a:visited {
    color: #cfccc8;
    text-decoration: none;
}

a:hover {
    color: #f7f4f7;
    text-decoration: none;
}

a .underline:hover {
    text-decoration: underline;
}

a:active {
    color: #cfccc8;
    text-decoration: none;
}

.bg-rd {
    background: radial-gradient(
        ellipse at center,
        rgba(125, 0, 0, 1) 0%,
        rgba(125, 0, 0, 1) 3%,
        rgba(33, 0, 0, 1) 91%,
        rgba(33, 0, 0, 1) 100%
    );
}

.line {
    height: 1px;
    background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        from(transparent),
        color-stop(50%, #c30000),
        to(transparent)
    );
    background-image: linear-gradient(90deg, transparent, #c30000 50%, transparent);
}

.line-dotted {
    border-bottom: 1px dotted #ffffff;
    margin: 20px 0px;
}

.card-header-bg {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(54, 54, 54, 1) 81%,
        rgba(31, 31, 31, 1) 88%,
        rgba(0, 0, 0, 1) 100%
    );
}

.card-bg {
    background: linear-gradient(
        to bottom,
        rgba(64, 0, 1, 1) 0%,
        rgba(64, 0, 1, 1) 1%,
        rgba(158, 0, 3, 1) 4%,
        rgba(96, 2, 3, 1) 34%,
        rgba(33, 0, 0, 1) 84%,
        rgba(33, 0, 0, 1) 93%,
        rgba(143, 0, 0, 1) 96%,
        rgba(20, 0, 0, 1) 98%,
        rgba(20, 0, 0, 1) 100%
    );
}